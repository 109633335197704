<template>
  <TooltipWrapper>
    <template #trigger>
      <ChipBadge :color="colorByState" rounded>
        {{ t(`Reviews.product.view.table.statusLabel.${state}`, t('Reviews.product.view.table.statusLabel.unknown')) }}
      </ChipBadge>
    </template>
    <template v-if="tooltipContentByState" #content>
      <div class="max-w-xs">{{ tooltipContentByState }}</div>
    </template>
  </TooltipWrapper>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { ChipBadge, TooltipWrapper } from '@ramp106/omrjs-core-ui'
import { SurveyResponseState, useSurveyResponseState } from '@/composables/useSurveyResponseState.ts'
import type { SurveyResponse } from '@/components/ReviewsManagement/Reviews/types.ts'

const props = defineProps<{
  surveyResponse: SurveyResponse
}>()

const { t } = useI18n()

const state = useSurveyResponseState(props.surveyResponse)

const colorByState = computed(() => {
  switch (state.value) {
    case SurveyResponseState.Published:
      return 'mint'
    case SurveyResponseState.Drafted:
    case SurveyResponseState.ReDrafted:
      return 'purple'
    case SurveyResponseState.Rejected:
      return 'error'
    case SurveyResponseState.Proposed:
      return 'yellow'
    case SurveyResponseState.Stale:
      return 'default'
    default:
      return 'default'
  }
})

const tooltipContentByState = computed(() => {
  switch (state.value) {
    case SurveyResponseState.Rejected:
      return t(
        `Reviews.product.review.rejected.${props.surveyResponse.rejectedReason}`,
        t('Reviews.product.view.table.statusLabel.unknown'),
      )
    case SurveyResponseState.Proposed:
      return t('Reviews.product.review.proposed.tooltip')
    case SurveyResponseState.Stale:
      return t('Reviews.product.review.stale.tooltip')
    case SurveyResponseState.ReDrafted:
      return t('Reviews.product.review.reDrafted.tooltip')
    default:
      return undefined
  }
})
</script>
